import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import styles from './index.module.scss';
import { toggleSidebar, closeSidebar } from '../../controller/actions/common';
const AppLayout = ({ runToggleSidebar, isLogged, isLoggedSSR, isSticky, isSidebarOpen, hideNav, children, runCloseSidebar, footerData, isLoggedDynamic, showExtoleReferFriend, router }) => {
    const isLoggedInInitial = typeof isLoggedSSR !== 'undefined' ? isLoggedSSR : isLogged;
    const [isLoggedIn, setIsLoggedIn] = useState(isLoggedInInitial);
    useEffect(() => {
        var _a, _b;
        if (((_b = (_a = router === null || router === void 0 ? void 0 : router.location) === null || _a === void 0 ? void 0 : _a.pathname) === null || _b === void 0 ? void 0 : _b.includes('/registration')) && !isLoggedInInitial) {
            setIsLoggedIn(isLoggedDynamic);
        }
    }, [isLoggedDynamic, isLoggedInInitial]);
    return (React.createElement("div", { className: `${styles.layoutWrap} react-layout` },
        React.createElement(Header, { runToggleSidebar: runToggleSidebar, isLogged: isLoggedIn, hideNav: hideNav, isSticky: isSticky, showExtoleReferFriend: showExtoleReferFriend }),
        React.createElement(Sidebar, { isSidebarOpen: isSidebarOpen, runCloseSidebar: runCloseSidebar, isLogged: isLoggedIn, hideNav: hideNav }),
        children,
        React.createElement(Footer, { footerCustomConfig: footerData, showExtoleReferFriend: showExtoleReferFriend })));
};
const mapStateToProps = ({ common, router, registration }) => ({
    isSidebarOpen: common.isSidebarOpen,
    isLogged: common.isLogged,
    hideNav: common.hideNav,
    router,
    isLoggedDynamic: registration === null || registration === void 0 ? void 0 : registration.isLoggedIn
});
const mapDispatchToProps = {
    runToggleSidebar: toggleSidebar,
    runCloseSidebar: closeSidebar
};
export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
