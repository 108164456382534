/* eslint-disable max-len */
import { DEFAULTS } from '@kmdpartners/ui-kit/src/CN_Design_System/blocks/Footer/constants';
export const FOOTER_DATA = Object.assign(Object.assign({}, DEFAULTS), { title: 'A little about us', mainText: [
        'Since we began in 2018 we’ve offered financial solutions to over 275,000 borrowers. If you’re one of the many Americans in a difficult financial situation, CreditNinja’s online loans may be the answer you’ve been looking for. And our online application makes the entire process easier than ever. No more waiting in long lines or filling out a bunch of paperwork. Apply from the comfort of your own home, and if approved, you may receive the funds as soon as the same day.* Choose a safe and reliable financial solution today',
        'Don’t let unplanned expenses ruin your budget. Apply for an online loan today with CreditNinja, and find out how we can help you.'
    ], ['disclosure']: [...DEFAULTS.disclosure] });
// --------------------------
// REDUX NAMESPACES
// --------------------------
export const START_SURVEY_ATTEMPT = 'home/START_SURVEY_ATTEMPT';
