import { useEffect, useState, useCallback } from 'react';
import onDOMContentLoaded from '@creditninja/shared/utils/onDOMContentLoaded';
import { TOUCHSCREEN_MAX_WIDTH } from '../constants/mediaQueries';
const useResize = ({ initialSyntheticWidth }) => {
    // initialSyntheticWidth can be used for some edge cases,
    // like when you need to know which device (mobile/desktop)
    // has requested a page during hydration
    const [isDesktop, setWindowSize] = useState(initialSyntheticWidth);
    const _handleResize = useCallback(() => {
        const desktopLayout = window.innerWidth > TOUCHSCREEN_MAX_WIDTH;
        // perf optimizations
        if ((isDesktop && desktopLayout) || (!isDesktop && !desktopLayout)) {
            return;
        }
        setWindowSize(desktopLayout);
    }, [isDesktop]);
    useEffect(() => {
        if (typeof window === 'undefined') {
            // for SSR purposes
            return () => null;
        }
        window.addEventListener('resize', _handleResize);
        // will trigger even if DOMContentLoad phase is omitted by React mount phase
        onDOMContentLoaded([_handleResize]);
        return () => {
            window.removeEventListener('resize', _handleResize);
            window.removeEventListener('DOMContentLoad', _handleResize);
        };
    }, [_handleResize]);
    return isDesktop;
};
export default useResize;
