import React, { memo } from 'react';
import TrustpilotWidget from '@creditninja/shared/widgets/trustpilot';
import styles from './index.module.scss';
const Trustpilot = memo(() => {
    return (React.createElement("div", { id: 'home_truspilot', className: styles.trustpilotWrap },
        React.createElement("div", { className: styles.trustpilotContainer },
            React.createElement(TrustpilotWidget, null))));
});
Trustpilot.displayName = 'Trustpilot';
export default Trustpilot;
