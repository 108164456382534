import { useState, useEffect } from 'react';
const useCalculateTopOffset = (isSidebarOpen, id) => {
    const [topOffset, setTopOffset] = useState(0);
    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }
        const headerElement = document.getElementById(id);
        const headerTopOffset = +(headerElement === null || headerElement === void 0 ? void 0 : headerElement.getBoundingClientRect().top);
        if (!!headerTopOffset || headerTopOffset === 0) {
            setTopOffset(headerTopOffset);
        }
    }, [isSidebarOpen]);
    return topOffset;
};
export default useCalculateTopOffset;
