/* eslint-disable max-len */
import React, { memo } from 'react';
import Accordion from '@kmdpartners/ui-kit/src/CN_Design_System/components/Accordion';
import makeBodyText from '@kmdpartners/ui-kit/src/CN_Design_System/utils/makeText';
import useResize from '../../../../hooks/useResize';
import styles from './index.module.scss';
const TABS = [
    {
        label: 'Flexible payment schedule',
        description: 'Get the cash you need at a fixed rate, with a flexible repayment plan and manageable installments tailored to fit your budget.'
    },
    {
        label: 'Convenient loan terms',
        description: 'CreditNinja is here to provide a safe and reliable option for borrowers struggling with low credit. Rates and longer repayment terms are designed to help you in times of need. CreditNinja gives borrowers options for larger loan amounts, competitive rates, and longer repayment periods than many other online lenders.'
    },
    {
        label: 'No need for excellent credit',
        description: "Don't worry, perfect credit history is not required to be approved. Your credit score is not the only thing considered. Apply today to find out if you qualify!"
    }
];
const Advantages = memo(({ isDesktopDevice }) => {
    const isDesktop = useResize({ initialSyntheticWidth: isDesktopDevice });
    const _renderContent = () => {
        if (!isDesktop) {
            return (React.createElement("div", { className: styles.accordionWrap },
                React.createElement(Accordion, { isDark: true, isFullWidth: true, itemsList: TABS })));
        }
        return (React.createElement("div", { className: styles.advantagesList }, TABS.map((item, key) => (React.createElement("div", { className: styles.item, key: key },
            React.createElement("h3", { className: makeBodyText('XL') },
                React.createElement("strong", null, item.label)),
            React.createElement("p", { className: `${makeBodyText('XL')}` }, item.description))))));
    };
    return (React.createElement("section", { id: 'home_advantages', className: styles.advantagesWrap },
        React.createElement("div", { className: styles.advantagesContainer },
            React.createElement("h2", { className: styles.title },
                React.createElement("strong", null, "CreditNinja to the rescue")),
            React.createElement("p", { className: `${makeBodyText('XL')} ${styles.text}` }, "We offer the immediate financial support you need with same-day online installment loan options.*"),
            _renderContent())));
});
Advantages.displayName = 'Advantages';
export default Advantages;
