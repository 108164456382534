import React, { useEffect } from 'react';
import Sidebar from '@kmdpartners/ui-kit/src/CN_Design_System/blocks/Sidebar';
import useGlobalClose from '../../hooks/useGlobalClose';
import useOverlay from '../../hooks/useOverlay';
import useCalculateTopOffset from '../../hooks/useCalculateTopOffset';
import useGlobalResize from '../../hooks/useGlobalResize';
const SidebarPanel = ({ isSidebarOpen, runCloseSidebar, isLogged, hideNav }) => {
    const topOffset = useCalculateTopOffset(isSidebarOpen, 'headerBlock');
    const isDesktop = useGlobalResize();
    useOverlay(isSidebarOpen);
    useGlobalClose(isSidebarOpen, 'sidebarBlock', runCloseSidebar);
    useEffect(() => {
        if (isDesktop && isSidebarOpen) {
            runCloseSidebar();
        }
    }, [isDesktop, isSidebarOpen, runCloseSidebar]);
    return (React.createElement(Sidebar, { hideNav: hideNav, onClickClose: runCloseSidebar, isOpen: isSidebarOpen, isLogged: isLogged, topOffset: topOffset }));
};
export default SidebarPanel;
