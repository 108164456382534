import React, { memo } from 'react';
import Hero from '../sections/Hero';
import Trustpilot from '../sections/Trustpilot';
import Attractions from '../sections/Attractions';
import Deeds from '../sections/Deeds';
import Testimonials from '../sections/Testimonials';
import Advantages from '../sections/Advantages';
import Questions from '../sections/Questions';
import Dojo from '../sections/Dojo';
import Knowledge from '../sections/Knowledge';
import Delimiter from '../components/Delimiter';
import { TOUCHSCREEN_DEVICES } from '../../../constants/mediaQueries';
import styles from './index.module.scss';
import '../styles/index.scss';
const HomeLayout = memo(({ deviceType }) => {
    const isDesktop = !TOUCHSCREEN_DEVICES.includes(deviceType);
    return (React.createElement("main", { className: styles.homePageWrap },
        React.createElement(Hero, null),
        React.createElement(Trustpilot, null),
        React.createElement(Attractions, null),
        React.createElement(Deeds, null),
        React.createElement(Testimonials, { isDesktopDevice: isDesktop }),
        React.createElement(Advantages, { isDesktopDevice: isDesktop }),
        React.createElement(Questions, null),
        React.createElement(Delimiter, null),
        React.createElement(Dojo, null),
        React.createElement(Delimiter, null),
        React.createElement(Knowledge, null)));
});
HomeLayout.displayName = 'HomeLayout';
export default HomeLayout;
