import { useEffect } from 'react';
const useOverlay = (isOverlayOpen) => {
    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }
        if (isOverlayOpen) {
            document.documentElement.style.overflow = 'hidden';
            return;
        }
        document.documentElement.style.overflow = '';
    }, [isOverlayOpen]);
};
export default useOverlay;
