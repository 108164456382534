import { useEffect, useState } from 'react';
const MIN_DESKTOP_WIDTH = 1025;
const useGlobalResize = () => {
    const [isDesktop, setIsDesktop] = useState(false);
    const _handleResize = () => {
        if (window.innerWidth >= MIN_DESKTOP_WIDTH) {
            setIsDesktop(true);
        }
        else {
            setIsDesktop(false);
        }
    };
    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }
        window.addEventListener('resize', _handleResize);
        return () => window.removeEventListener('resize', _handleResize);
    });
    return isDesktop;
};
export default useGlobalResize;
