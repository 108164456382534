/* eslint-disable max-len */
import React, { memo } from 'react';
import BulletItems from '@kmdpartners/ui-kit/src/CN_Design_System/components/BulletItems';
import { ICONS } from '@kmdpartners/ui-kit/src/CN_Design_System/constants/icons';
import makeBodyText from '@kmdpartners/ui-kit/src/CN_Design_System/utils/makeText';
import styles from './index.module.scss';
const BULLETS = [
    {
        iconName: ICONS.x64.finger,
        title: 'Apply online easily',
        text: 'Our online application is quick and simple. All you need is to provide some basic information.'
    },
    {
        iconName: ICONS.x64.check,
        title: 'Find out if you qualify...fast!',
        text: 'Don’t worry, your credit score is not the only thing considered! If approved, you’ll see your personalized loan amount, rate, and terms. '
    },
    {
        iconName: ICONS.x64.dollar,
        title: 'Get your money quickly',
        text: 'Once you sign for your loan, your money may get directly deposited the same day.*'
    }
];
const Attractions = memo(() => {
    return (React.createElement("section", { id: 'home_attractions', className: styles.attractionsWrap },
        React.createElement("div", { className: styles.attractionsBackground }),
        React.createElement("div", { className: styles.attractionsContainer },
            React.createElement("h2", { className: styles.title },
                React.createElement("strong", null, "How CreditNinja loan options work")),
            React.createElement("p", { className: `${makeBodyText('XL')} ${styles.text}` }, "If approved, once you sign for your loan, your money may get directly deposited the same day.*"),
            React.createElement("div", { className: styles.bulletsWrap },
                React.createElement(BulletItems, { items: BULLETS })))));
});
Attractions.displayName = 'Attractions';
export default Attractions;
