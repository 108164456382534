/* eslint-disable max-len */
import React, { memo } from 'react';
import Button from '@kmdpartners/ui-kit/src/CN_Design_System/components/Button';
import Accordion from '@kmdpartners/ui-kit/src/CN_Design_System/components/Accordion';
import { ICONS } from '@kmdpartners/ui-kit/src/CN_Design_System/constants/icons';
import makeIconName from '@kmdpartners/ui-kit/src/CN_Design_System/utils/makeIconName';
import styles from './index.module.scss';
const TABS = [
    {
        label: 'How much can I borrow?',
        description: 'Generally, unsecured loans are offered up to $5,000. However, we encourage you to apply because your resident state will affect both the minimum and maximum loan amounts offered and whether we make a loan directly or a loan is made by an FDIC-insured bank or other non-affiliated lender.'
    },
    {
        label: 'What is an installment loan?',
        description: 'An installment loan is a loan that requires a fixed number of regular payments that are substantially equal in amount. Payments on an installment loan are calculated over a set duration. The installment loans offered are fully amortizing, meaning that you will pay-off all principal by the maturity date if you pay the loan as scheduled.'
    },
    {
        label: 'What is an APR?',
        description: 'An annual percentage rate (APR) is the total cost of credit, including interest and all other finance charges such as a loan origination fee (if one is charged in your state). The APR expresses your loan’s Finance Charge as an annual rate.'
    },
    {
        label: 'I don’t have a bank account, can I still apply for a loan?',
        description: 'No, a checking account is required to accept your loan funds.'
    },
    {
        label: 'How will my loan be funded?',
        description: 'If approved, your funds will be deposited directly into your checking account via direct deposit.'
    }
];
const Questions = memo(() => {
    return (React.createElement("section", { id: 'home_questions', className: styles.questionsWrap },
        React.createElement("div", { className: styles.questionsContainer },
            React.createElement("i", { className: makeIconName(ICONS.x64.five, 'x64') }),
            React.createElement("h2", { className: styles.title },
                React.createElement("strong", null, "Top five loan questions")),
            React.createElement("div", { className: styles.accordionWrap },
                React.createElement(Accordion, { isFullWidth: true, itemsList: TABS })),
            React.createElement(Button, { ID: 'apply_now_button__questions', btnSize: 'L', isFullWidth: true, type: 'primaryBlue', isBold: true, label: 'Apply Now', href: '/registration?utm_source=dojo' }))));
});
Questions.displayName = 'Questions';
export default Questions;
