import React, { useEffect } from 'react';
import Spinner from '@kmdpartners/ui-kit/src/CN_Design_System/components/Spinner';
// @ts-ignore
import './index.scss';
const TrustpilotWidget = () => {
    useEffect(() => {
        if (typeof document === 'undefined') {
            return;
        }
        const script = document.createElement('script');
        script.rel = 'prefetch'; // rel and as is not defined in HTMLScriptElement
        script.as = 'script';
        script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);
    return (React.createElement("div", { className: 'trustpilot-widget', "data-locale": 'en-US', "data-template-id": '53aa8912dec7e10d38f59f36', "data-businessunit-id": '5ab193725857130001e19797', "data-style-height": '140px', "data-style-width": '100%', "data-theme": 'light', "data-stars": '5,4', "data-schema-type": 'Organization' },
        React.createElement("a", { href: 'https://www.trustpilot.com/review/www.creditninja.com', target: '_blank', rel: 'noreferrer' }, "Trustpilot"),
        React.createElement(Spinner, { color: 'blue', size: 'L' })));
};
export default TrustpilotWidget;
