import { TOGGLE_SIDEBAR, CLOSE_SIDEBAR, SET_DISCLAIMERS, TOGGLE_NAV_BAR_STATE } from '../../constants';
export const toggleSidebar = () => ({
    type: TOGGLE_SIDEBAR
});
export const closeSidebar = () => ({
    type: CLOSE_SIDEBAR
});
export const setDisclaimers = (disclaimers) => ({
    disclaimers,
    type: SET_DISCLAIMERS
});
export const toggleNavBar = () => ({
    type: TOGGLE_NAV_BAR_STATE
});
