import { applyMiddleware, compose, createStore } from 'redux';
import reduxLogger from './middleware/reduxLogger';
import makeRootReducer from './services/reducerSSRReducer';
import saga, { rootSaga, runSaga } from './services/rootSaga';
const rootStore = (initialState = {}) => {
    // ======================================================
    // Middleware Configuration
    // ======================================================
    const middleware = [saga];
    const enhancers = [];
    if (process.env.NODE_ENV === 'development') {
        middleware.push(reduxLogger);
    }
    // ======================================================
    // Store Instantiation and HMR Setup
    // ======================================================
    const store = createStore(makeRootReducer(), initialState, compose(applyMiddleware(...middleware), ...enhancers));
    // @ts-ignore
    store.runSaga = runSaga;
    runSaga(rootSaga);
    store.asyncReducers = {}; // activating async reducers replacement
    return store;
};
export default rootStore();
