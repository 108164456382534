import { useEffect } from 'react';
const useGlobalClose = (isOpen, id, onClickClose) => {
    const _handleGlobalCloseClick = (e) => {
        const { target } = e;
        if (isOpen && target.id === id) {
            onClickClose(e);
        }
    };
    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }
        window.addEventListener('click', _handleGlobalCloseClick);
        return () => window.removeEventListener('click', _handleGlobalCloseClick);
    }, [isOpen]);
};
export default useGlobalClose;
