/* eslint-disable max-len */
import React, { memo } from 'react';
import BlogPost from '@kmdpartners/ui-kit/src/CN_Design_System/components/BlogPost';
import makeBodyText from '@kmdpartners/ui-kit/src/CN_Design_System/utils/makeText';
import styles from './index.module.scss';
const BLOG_POSTS = [
    {
        tag: 'budgeting',
        title: 'How to control spending habits',
        description: "Learn good financial habits even if you don't have a steady income.",
        linkHref: 'https://www.creditninja.com/blog/how-to-control-spending-habits/',
        imageSrc: 'https://www.creditninja.com/wp-content/uploads/2022/11/how-to-control-spending-habits.jpg',
        isFullWidth: true
    },
    {
        tag: 'budgeting',
        title: 'What is cash stuffing?',
        description: 'Find out more about new way to organize budget and how it works',
        linkHref: 'https://www.creditninja.com/blog/what-is-cash-stuffing/',
        imageSrc: 'https://www.creditninja.com/wp-content/uploads/2023/03/cash-stuffing.jpg',
        isFullWidth: true
    },
    {
        tag: 'credit',
        title: 'How to get your credit report?',
        description: 'Learn what information is on your credit reports and how to get one',
        linkHref: 'https://www.creditninja.com/blog/how-to-get-your-credit-report/',
        imageSrc: 'https://www.creditninja.com/wp-content/uploads/2020/06/get-your-credit-report-scaled.jpg',
        isFullWidth: true
    }
];
const Dojo = memo(() => {
    return (React.createElement("section", { id: 'home_dojo', className: styles.dojoWrap },
        React.createElement("div", { className: styles.dojoContainer },
            React.createElement("h2", { className: styles.title },
                React.createElement("strong", null, "CreditNinja Dojo")),
            React.createElement("p", { className: `${makeBodyText('XL')} ${styles.text}` }, "Check out the CreditNinja Dojo for helpful blogs with financial tips and know-how. Step into the Dojo to begin your personal finance training."),
            React.createElement("div", { className: styles.blogsWrap }, BLOG_POSTS.map((item, key) => (React.createElement("div", { className: styles.item, key: key },
                React.createElement(BlogPost, Object.assign({}, item)))))))));
});
Dojo.displayName = 'Dojo';
export default Dojo;
