/* eslint-disable max-len */
import React from 'react';
// @ts-ignore
import './index.scss';
const ExtoleReferFriendWidget = () => {
    const _handleClick = () => {
        // @ts-ignore
        if (window.extole) {
            // @ts-ignore
            window.extoleCreateZone({ name: 'global_header', element_id: 'extole_zone_global_header' });
        }
    };
    /* const _injectCoreScripts = () => {
      const script = document.createElement('script');
  
      script.src = 'https://share.creditninja.com/core.js';
      script.async = true;
  
      document.body.appendChild(script);
    };
  
    const _injectExtoleWatcher = () => {
      const script = document.createElement('script');
  
      script.text =
        '/!* Start Extole *!/ (function(c,e,k,l,a){c[e]=c[e]||{};for(c[e].q=c[e].q||[];a<l.length;)k(l[a++],c[e])})(window,"extole",function(c,e){e[c]=e[c]||function(){e.q.push([c,arguments])}},["createZone"],0); /!* End Extole *!/ const extoleCreateZone = ({name, element_id}) => { extole.createZone({ name, element_id }); }';
  
      document.body.appendChild(script);
    };
  
    useEffect(() => {
      if (typeof document === 'undefined' || typeof window === 'undefined') {
        return;
      }
  
      _injectCoreScripts();
      _injectExtoleWatcher();
    }, []); */
    return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    React.createElement("div", { role: 'button', className: 'referral_header', tabIndex: 0, onClick: _handleClick, "aria-label": 'Click Referer Link' },
        React.createElement("span", { id: 'extole_zone_global_header' })));
};
export default ExtoleReferFriendWidget;
