import React from 'react';
import Footer from '@kmdpartners/ui-kit/src/CN_Design_System/blocks/Footer';
import { DEFAULTS } from '@kmdpartners/ui-kit/src/CN_Design_System/blocks/Footer/constants';
// @ts-ignore
import discloseDoc from '../../../../assets/images/account-opening-disclosure.pdf';
// @ts-ignore
import febPrivatePolicyDoc from '../../../../assets/images/feb-privacy-policy.pdf';
const FooterBlock = ({ footerCustomConfig, showExtoleReferFriend }) => {
    var _a;
    const _linksModifier = (link, index) => {
        const INDEX_DISPOSITION = 9;
        if ([0, 1].map((i) => i + INDEX_DISPOSITION).includes(index)) {
            return Object.assign(Object.assign({}, link), { target: '_self', href: [febPrivatePolicyDoc, discloseDoc][index - INDEX_DISPOSITION] });
        }
        return Object.assign(Object.assign({}, link), { target: '_self' });
    };
    const footerData = Object.assign(Object.assign({}, DEFAULTS), { title: (footerCustomConfig === null || footerCustomConfig === void 0 ? void 0 : footerCustomConfig.title) || DEFAULTS.title, mainText: (footerCustomConfig === null || footerCustomConfig === void 0 ? void 0 : footerCustomConfig.mainText) || DEFAULTS.mainText, disclosure: (footerCustomConfig === null || footerCustomConfig === void 0 ? void 0 : footerCustomConfig.disclosure) || DEFAULTS.disclosure, 
        // modifying some links because of
        // versioning system on the RubyOnRails backend
        links: (_a = DEFAULTS.links) === null || _a === void 0 ? void 0 : _a.map(_linksModifier) });
    return React.createElement(Footer, { dataToAppear: footerData, withReferFriend: showExtoleReferFriend });
};
export default FooterBlock;
