import React, { memo } from 'react';
import DeedsInNumbers from '@kmdpartners/ui-kit/src/CN_Design_System/components/DeedsInNumbers';
import styles from './index.module.scss';
const DEEDS = [
    {
        label: 'established',
        description: '2018'
    },
    {
        label: 'loans',
        description: '475,000+'
    },
    {
        label: 'customers',
        description: '275,000+'
    }
];
const Deeds = memo(() => {
    return (React.createElement("div", { id: 'home_deeds', className: styles.deedsWrap },
        React.createElement("div", { className: styles.deedsContainer },
            React.createElement(DeedsInNumbers, { itemsList: DEEDS }))));
});
Deeds.displayName = 'Deeds';
export default Deeds;
