import initialState from '../initialState/common';
import { TOGGLE_SIDEBAR, CLOSE_SIDEBAR, SET_DISCLAIMERS, TOGGLE_NAV_BAR_STATE } from '../../constants';
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [TOGGLE_SIDEBAR]: (state) => (Object.assign(Object.assign({}, state), { isSidebarOpen: !state.isSidebarOpen })),
    [CLOSE_SIDEBAR]: (state) => (Object.assign(Object.assign({}, state), { isSidebarOpen: false })),
    [SET_DISCLAIMERS]: (state, action) => (Object.assign(Object.assign({}, state), { disclaimers: action.disclaimers })),
    [TOGGLE_NAV_BAR_STATE]: (state) => (Object.assign(Object.assign({}, state), { hideNav: !state.hideNav }))
};
// ------------------------------------
// Reducer
// ------------------------------------
const reducer = (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
};
export default reducer;
