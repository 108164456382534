/* eslint-disable max-len */
import React, { memo } from 'react';
import Static from '@kmdpartners/ui-kit/src/CN_Design_System/components/Testimonials/Static';
import Carousel from '@kmdpartners/ui-kit/src/CN_Design_System/components/Testimonials/Carousel';
import useResize from '../../../../hooks/useResize';
import styles from './index.module.scss';
const TESTIMONIALS = [
    {
        testimonial: 'CreditNinja was awesome! I was approved in no time and the funds were in my bank account the same day! Plus, the payments are within my budget.',
        author: 'Lanea F.'
    },
    {
        testimonial: 'By far the fastest and easiest online loan I have ever gotten. Thanks CreditNinja!',
        author: 'Jay B.'
    },
    {
        testimonial: 'CreditNinja was a fast and easy online loan. It was very helpful getting the cash ASAP right into my bank account. Definitely would recommend! Very happy with the service and online layout for repayment. Thank you!!!',
        author: 'Severiano C.'
    }
];
const Testimonials = memo(({ isDesktopDevice }) => {
    const isDesktop = useResize({ initialSyntheticWidth: isDesktopDevice });
    return (React.createElement("section", { id: 'home_testimonials', className: styles.testimonialsWrap },
        React.createElement("div", { className: styles.testimonialsContainer },
            React.createElement("h2", { className: styles.title },
                React.createElement("strong", null, "What our customers are saying about us")),
            isDesktop ? React.createElement(Static, { itemsList: TESTIMONIALS }) : React.createElement(Carousel, { itemsList: TESTIMONIALS }))));
});
Testimonials.displayName = 'Testimonials';
export default Testimonials;
