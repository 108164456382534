import React, { memo } from 'react';
import FeatureButton from '@kmdpartners/ui-kit/src/CN_Design_System/components/FeatureButton/Item';
import { ICONS } from '@kmdpartners/ui-kit/src/CN_Design_System/constants/icons';
import styles from './index.module.scss';
const TABS = [
    {
        iconName: ICONS.x48.loan,
        text: 'Personal Loans',
        linkHref: 'https://www.creditninja.com/personal-loans/',
        target: '_self'
    },
    {
        iconName: ICONS.x48.clock,
        text: 'Installment loans',
        linkHref: 'https://www.creditninja.com/installment-loans/',
        target: '_self'
    },
    {
        iconName: ICONS.x48.minus,
        text: 'Bad Credit Loans',
        linkHref: 'https://www.creditninja.com/bad-credit-loans/',
        target: '_self'
    },
    {
        iconName: ICONS.x48.check,
        text: 'No Credit Check Loans',
        linkHref: 'https://www.creditninja.com/no-credit-check-loans/',
        target: '_self'
    },
    {
        iconName: ICONS.x48.dollar,
        text: 'Cash Advance Loans',
        linkHref: 'https://www.creditninja.com/cash-advance-loans/',
        target: '_self'
    },
    {
        iconName: ICONS.x48.calendar,
        text: 'Payday Loans',
        linkHref: 'https://www.creditninja.com/payday-loans/',
        target: '_self'
    }
];
const Knowledge = memo(() => {
    return (React.createElement("section", { id: 'home_knowledge', className: styles.knowledgeWrap },
        React.createElement("div", { className: styles.knowledgeBackground }),
        React.createElement("div", { className: styles.knowledgeContainer },
            React.createElement("h2", { className: styles.title },
                React.createElement("strong", null, "Learn more about online loans")),
            React.createElement("div", { className: styles.itemsWrap }, TABS.map((item, key) => (React.createElement("div", { className: styles.item, key: key },
                React.createElement(FeatureButton, { item: item }))))))));
});
Knowledge.displayName = 'Knowledge';
export default Knowledge;
