import createSagaMiddleware from 'redux-saga';
import createSagaMiddlewareHelpers from 'redux-saga-watch-actions/lib/middleware';
import { all } from 'redux-saga/effects';
const sagaMiddleware = createSagaMiddleware();
const runSaga = (saga) => sagaMiddleware.run(saga);
const { injectSaga, cancelTask } = createSagaMiddlewareHelpers(sagaMiddleware); // <-- bind to sagaMiddleware.run
function* rootSaga() {
    yield all([]);
}
export { cancelTask, injectSaga, runSaga, rootSaga };
export default sagaMiddleware;
