import { combineReducers } from 'redux';
import common from '../../controller/reducers/common';
export const makeRootReducer = (asyncReducers) => {
    return combineReducers(Object.assign(Object.assign({}, asyncReducers), { common }));
};
export const injectReducer = (store, { key, reducer }) => {
    if (Object.hasOwnProperty.call(store.asyncReducers, key)) {
        return;
    }
    store.asyncReducers[key] = reducer;
    store.replaceReducer(makeRootReducer(store.asyncReducers));
};
export default makeRootReducer;
